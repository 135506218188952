@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import '~@flaticon/flaticon-uicons/css/all/all';
@import url('node_modules/@leapfinance/geebee-component-library/src/styles/tailwind.css');

@layer utilities {
  .custom-scrollbar::-webkit-scrollbar {
    width: 10px;
    height: 5px;
  }

  .custom-scrollbar::-webkit-scrollbar-track {
    background: transparent;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: #eaecf0;
    border-radius: 8px;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #d0d1d4;
  }
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

.custom-scroll::-webkit-scrollbar {
  width: 8px;
  background-color: #e3e2ff30;
}

.custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  min-height: 80px;
  background-color: #e3e2ff;
}

.filter-scroll::-webkit-scrollbar {
  height: 8px;
  margin-top: 4;
  width: 10px;
}

.filter-scroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  width: 10px;
}

/*hide arrows in input type number*/
/* Chrome, Safari, Edge, Opera */
/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* button,
.button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  background-color: initial !important;
} */

/* .headerTabs {
  border-bottom: 5px solid #443eff;
  padding: 0 0 15px;
  color: #443eff !important;
} */

.filterBar {
  height: 60px;
  background-color: #f5f5f5;
  align-items: center;
  display: flex;
  width: 95%;
  margin-left: 3rem;
}

.freezedHeader {
  position: fixed;
  top: 0px;
  overflow-x: scroll !important;
}

.profileNameHolder {
  width: 40px;
  height: 40px;
  background-color: #c0e2f3;
  border-radius: 50%;
  color: #2f9ed5;
  text-align: center;
  padding-top: 0.5rem;
  font-weight: 700;
  margin-right: 2rem;
}

.item-row:hover {
  overflow: visible !important;
  white-space: normal !important;
  height: auto !important;
}

.addUserLabel {
  color: #424242;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  line-height: 30px;
}

.autocomplete-student-csp {
  font-size: 12px !important;
  height: 20px !important;
}

.countrySelect__value-container {
  min-height: 3.4rem !important;
  height: auto !important;
}

.countrySelect__option--is-selected {
  background-color: #deebff !important;
  color: #000 !important;
}

.countrySelectError {
  border: 1px solid #d32f2f;
  border-radius: 5px;
}

.countrySelectErrorText {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
  color: #d32f2f;
}

.app-priority {
}

.app-priority__control {
  height: 20px;
  min-height: 20px;
}

.app-priority__highlight {
  border: 3px solid red;
}

.overlayActive {
  z-index: 300;
}

.overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 300;
}

/* Hides the scrollbar inside this particular element with this ID */
#CF_USP_FILTER_CHIPS_CONTAINER::-webkit-scrollbar {
  display: none;
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
    /* Chrome, Safari */
    print-color-adjust: exact;
    /* Firefox */
    /* padding-top: 20mm;  */
    /* background: #ffffff; Set your desired background color */
  }

  .page-break {
    page-break-after: always;
    /* Always insert a page break after this element */
  }

  .mobile-chat-container {
    display: none;
  }

  .no-page-break-inside {
    page-break-inside: avoid;
    /* Avoid breaking inside this element if possible */
  }

  @page {
    size: auto;
    margin: 0;
    /* padding-top: 20mm;  */
  }
}

@media (-webkit-min-device-pixel-ratio: 0) {
  body {
    -webkit-print-color-adjust: exact;
    /* Adjust color for WebKit */
  }

  @page {
    size: auto;
    margin: 0;
    padding-top: 20mm;
  }
}

body::-webkit-scrollbar {
  /* width: 8px;
  height: 5px;
  opacity: 0;
  background: transparent; */
}

body::-webkit-scrollbar:hover {
  /* opacity: 1; */
}

body::-webkit-scrollbar-track {
  /* background: transparent; */
}

body::-webkit-scrollbar-thumb {
  background-color: green;
  /* background: #b3b2c2; */
  /* border-radius: 8px; */
}

body::-webkit-scrollbar-thumb:hover {
  background: #d0d1d4;
}

.rhap_container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  line-height: 1;
  font-family: inherit;
  width: 100%;
  padding: 14px;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(67, 54, 149, 0.08);
  border: 1px solid #e3e2ff;
  border-radius: 12px;
}

.rhap_container:focus:not(:focus-visible) {
  outline: 0;
}

.rhap_container svg {
  vertical-align: initial;
}

.rhap_header {
  margin-bottom: 10px;
}

.rhap_footer {
  margin-top: 5px;
}

.rhap_main {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.rhap_stacked .rhap_controls-section {
  margin-top: 8px;
}

.rhap_horizontal {
  flex-direction: row;
}

.rhap_horizontal .rhap_controls-section {
  margin-left: 8px;
}

.rhap_horizontal-reverse {
  flex-direction: row-reverse;
}

.rhap_horizontal-reverse .rhap_controls-section {
  margin-right: 8px;
}

.rhap_stacked-reverse {
  flex-direction: column-reverse;
}

.rhap_stacked-reverse .rhap_controls-section {
  margin-bottom: 8px;
}

.rhap_progress-section {
  display: flex;
  flex: 3 1 auto;
  align-items: center;
}

.rhap_progress-container {
  display: flex;
  align-items: center;
  height: 20px;
  flex: 1 0 auto;
  align-self: center;
  margin: 0 calc(10px + 1%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  pointer-events: none;
}

.rhap_progress-container:focus:not(:focus-visible) {
  outline: 0;
}

.rhap_time {
  color: #333;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
}

.rhap_progress-bar {
  box-sizing: border-box;
  position: relative;
  z-index: 0;
  width: 100%;
  height: 5px;
  background-color: #dddddd;
  border-radius: 2px;
}

.rhap_progress-filled {
  height: 100%;
  position: absolute;
  z-index: 2;
  background-color: #7672f7;
  border-radius: 2px;
}

.rhap_progress-bar-show-download {
  background-color: rgba(221, 221, 221, 0.5);
}

.rhap_download-progress {
  height: 100%;
  position: absolute;
  z-index: 1;
  background-color: #eeedf9;
  border-radius: 2px;
}

.rhap_progress-indicator {
  box-sizing: border-box;
  position: absolute;
  z-index: 3;
  width: 20px;
  height: 20px;
  margin-left: -10px;
  top: -8px;
  background: #868686;
  border-radius: 50px;
  box-shadow: rgba(134, 134, 134, 0.5) 0 0 5px;
  display: none;
}

.rhap_controls-section {
  flex: 1 1 auto;
  justify-content: space-between;
  align-items: center;
  display: none;
}

.rhap_additional-controls {
  display: flex;
  flex: 1 0 auto;
  align-items: center;
}

.rhap_repeat-button {
  font-size: 26px;
  width: 26px;
  height: 26px;
  color: #868686;
  margin-right: 6px;
}

.rhap_main-controls {
  flex: 0 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rhap_main-controls-button {
  margin: 0 3px;
  color: #868686;
  font-size: 35px;
  width: 35px;
  height: 35px;
}

.rhap_play-pause-button {
  font-size: 40px;
  width: 40px;
  height: 40px;
}

.rhap_volume-controls {
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end;
  align-items: center;
}

.rhap_volume-button {
  flex: 0 0 26px;
  font-size: 26px;
  width: 26px;
  height: 26px;
  color: #868686;
  margin-right: 6px;
}

.rhap_volume-container {
  display: flex;
  align-items: center;
  flex: 0 1 100px;
  user-select: none;
  -webkit-user-select: none;
}

.rhap_volume-bar-area {
  display: flex;
  align-items: center;
  width: 100%;
  height: 14px;
  cursor: pointer;
}

.rhap_volume-bar-area:focus:not(:focus-visible) {
  outline: 0;
}

.rhap_volume-bar {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 4px;
  background: #dddddd;
  border-radius: 2px;
}

.rhap_volume-indicator {
  box-sizing: border-box;
  position: absolute;
  width: 12px;
  height: 12px;
  margin-left: -6px;
  left: 0;
  top: -4px;
  background: #868686;
  opacity: 0.9;
  border-radius: 50px;
  box-shadow: rgba(134, 134, 134, 0.5) 0 0 3px;
  cursor: pointer;
}

.rhap_volume-indicator:hover {
  opacity: 0.9;
}

.rhap_volume-filled {
  height: 100%;
  position: absolute;
  z-index: 2;
  background-color: #868686;
  border-radius: 2px;
}

/* Utils */
.rhap_button-clear {
  background-color: transparent;
  border: none;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
}

.rhap_button-clear:hover {
  opacity: 0.9;
  transition-duration: 0.2s;
}

.rhap_button-clear:active {
  opacity: 0.95;
}

.rhap_button-clear:focus:not(:focus-visible) {
  outline: 0;
}

/*
 * react-circular-progressbar styles
 * All of the styles in this file are configurable!
 */

.CircularProgressbar {
  /*
   * This fixes an issue where the CircularProgressbar svg has
   * 0 width inside a "display: flex" container, and thus not visible.
   */
  width: 100%;
  /*
   * This fixes a centering issue with CircularProgressbarWithChildren:
   * https://github.com/kevinsqi/react-circular-progressbar/issues/94
   */
  vertical-align: middle;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #3e98c7;
  stroke-linecap: round;
  -webkit-transition: stroke-dashoffset 0.5s ease 0s;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #d6d6d6;
  /* Used when trail is not full diameter, i.e. when props.circleRatio is set */
  stroke-linecap: round;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #3e98c7;
  font-size: 24px;
  dominant-baseline: auto;
  text-anchor: middle;
  font-weight: bold;
}

.CircularProgressbar .CircularProgressbar-background {
  fill: #d6d6d6;
}

/*
 * Sample background styles. Use these with e.g.:
 *
 *   <CircularProgressbar
 *     className="CircularProgressbar-inverted"
 *     background
 *     percentage={50}
 *   />
 */
.CircularProgressbar.CircularProgressbar-inverted
  .CircularProgressbar-background {
  fill: #3e98c7;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
  fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
  stroke: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
  stroke: transparent;
}

.fill_the_blank input {
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  margin: 1px 4px;
  padding: 1px 4px;
}

.fill_the_blank_success {
  border: 1px solid #6da559;
  background-color: #fafff8;
  color: #6da559;
  border-radius: 6px;
  margin: 0 4px;
  padding: 1px 4px;
}

.fill_the_blank_error {
  border: 1px solid #ba3b3b;
  background-color: #fff8f8;
  color: #ba3b3b;
  border-radius: 6px;
  margin: 0 4px;
  padding: 1px 4px;
}

.passageSection {
  padding: 0 10px;
}

.passageSection p,
.passageSection img {
  margin: 16px 0px;
}

.selected-courses-side-panel::-webkit-scrollbar {
  margin-top: 4;
  width: 6px;
}

.selected-courses-side-panel::-webkit-scrollbar-thumb {
  border-radius: 8px;
  width: 6px;
  background-color: #dbdbdb;
}

.csp-logo-display-styles {
  max-width: 150px;
  max-height: 40px;
}

.rewards-panel {
  background-image: linear-gradient(
    rgba(252, 252, 252, 0),
    rgb(23 22 40 / 77%)
  );
}

.slider-scroll::-webkit-scrollbar {
  height: 4px;
  background-color: #eeeff2;
  border-radius: 8px;
}

.slider-scroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  min-height: 80px;
  background-color: #cbcad5;
}

.new-chip-gradient {
  background: linear-gradient(106deg, #ff3eb5 10.58%, #292599 82.59%);
}

.shimmer-new {
  position: relative;
  overflow: hidden;
}

.shimmer-new::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 35%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent 0%,
    rgba(255, 255, 255, 0.5) 50%,
    transparent 100%
  );
  transform: skewX(-15deg);
  animation: shimmer 2s infinite;
}

@keyframes shimmer {
  0% {
    transform: skewX(-15deg) translateX(-150%);
  }

  100% {
    transform: skewX(-15deg) translateX(250%);
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Add these classes to your existing styles */
.dropdown-animate {
  animation: slideDown 0.4s ease-out;
}

.overlay-animate {
  animation: fadeIn 0.4s ease-out;
}


.laser-shimmer {
  position: absolute;
    top: -4px;
    left: 4px;
    width: 8px;
    height: 200%;
    background: linear-gradient(
      90deg,
      transparent 0%,
      rgba(255, 255, 255, 0.5) 50%,
      transparent 100%
    );
    transform: translateX(-100%) rotate(45deg);
    transform-origin: 0 0;
    animation: laser-shine 3s infinite;
    pointer-events: none;
}

@keyframes laser-shine {
  0% {
    transform: translateX(-50%) rotate(45deg);
  }
  100% {
    transform: translateX(2000%) rotate(45deg); /* Move all the way across and beyond */
  }
}